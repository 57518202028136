
import { defineComponent, ref } from 'vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import * as Yup from 'yup';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import ApiService from '@/core/services/ApiService';

export default defineComponent({
  name: 'ExpirePasswordReset',
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      email: '',
      loading: false,
    };
  },
  methods: {
    async formSubmit() {
      let formData = new FormData();
      formData.set('email', this.email);
      this.loading = true;
      await ApiService.post('auth/forgotPass', formData)
        .then((response) => {
          this.loading = false;
          if (response.data.status === 'success') {
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.email = '';
              // redirect to signin or login page
              this.$router.push('/sign-in');
            });
          } else if (response.data.status === 'error') {
            Swal.fire({
              title: 'Error',
              text: response.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
  setup() {
    const PassResetSchema = Yup.object().shape({
      email: Yup.string()
        .email('Must be valid email')
        .required()
        .label('Email'),
    });

    //Form submit function

    return {
      PassResetSchema,
    };
  },
});
